import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixin from "./mixin";
import Axios from "axios";
import _ from "lodash";
import VueRouter from "vue-router";
import "./assets/css/tailwind.css";
import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.mixin(mixin);
Object.defineProperty(Vue.prototype, "_", {
  value: _,
});

Axios.defaults.baseURL = process.env.VUE_APP_URLAPP;
Axios.interceptors.request.use(
  (config) => {
    config.timeout = 10000;
    config.timeoutErrorMessage = "Time Out";
    const user = store.get("user");
    if (user) {
      if (!config.headers) {
        config.headers = [];
      }
      if (!config.headers["Authorization"]) {
        config.headers[`Authorization`] = process.env.VUE_APP_TOKEN;
      }
      if (!config.headers["Content-type"]) {
        config.headers["Content-type"] = "application/json";
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject("request", error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    if (response.status == 200) {
      return Promise.resolve(response.data);
    } else {
      Promise.reject(response);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
