import VueRouter from "vue-router";
import store from "./store";
const routes = [
  { path: "/", name: "/", component: () => import("@/views/HomePage.vue") },
  {
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/home",
    component: () => import("@/views/HomePage.vue"),
  },
  { path: "/buy", name: "buy", component: () => import("@/views/BuyPage.vue") },
  {
    path: "/changepassword",
    component: () => import("@/views/ChangePasswordPage.vue"),
  },
  {
    path: "/profile",
    component: () => import("@/views/ProfilePage.vue"),
  },
  {
    path: "/paymenthistory",
    component: () => import("@/views/PaymentHistoryPage.vue"),
  },
  {
    path: "/register",
    component: () => import("@/views/RegisterPage.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (!["/login", "/register"].includes(to.path)) {
    if (!store.get("user")) {
      router.replace({ path: "/login" });
    }
  }
  next();
});

export default router;
