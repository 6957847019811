<template>
  <div id="app" class="h-full overflow-y-hidden">
    <router-view />
  </div>
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "App",
  computed: {
    user: get("user"),
  },
};
</script>

<style>
@font-face {
  font-family: "NotoLao";
  src: url("./assets/fonts/NotoSansLao-VariableFont_wdth,wght.ttf")
    format("truetype");
}
#app,
.swal2-popup,
.swal2-title,
.swal2-confirm,
.swal2-cancel {
  font-family: NotoLao;
}
</style>
